class LazyLightbox {

    constructor(scriptUrl, styleUrl) {
        document.addEventListener('DOMContentLoaded', () => {
            if (document.querySelectorAll('a[data-lightbox]').length) {
                const style = document.createElement('link')
                style.rel = 'stylesheet'
                style.href = styleUrl
                document.body.appendChild(style)

                const script = document.createElement('script')
                script.src = scriptUrl
                document.body.appendChild(script)
            }
        })
    }
}

new LazyLightbox('/js/lightbox.min.js', '/css/lightbox.css')